export const NOTIFY_DISMISS_TIMEOUT = 5000;

export const CONTACT_CONFIG = {
  serviceID: 'mailjet',
  templateID: 'compassglass_contact',
  form: '#compassglass-contact-form',
  userID: 'user_Rl97NCQ767rgxx9qvgltk',
};

export const GOOGLE_MAP_CONFIGS = {
  george: {
    title: 'Compass Glass George',
    src:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.626018274029!2d22.465403215213016!3d-33.97645068062666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dd61adaf59a110f%3A0xdfa9df8f2d7b56b4!2s120%20Fichat%20St%2C%20George%20Industria%2C%20George%2C%206536!5e0!3m2!1sen!2sza!4v1596397075420!5m2!1sen!2sza',
  },
  capeTown: {
    title: 'Compass Glass Cape Town',
    src:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.4581771388994!2d18.695958315210394!3d-33.87785198065376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5168d3f00469%3A0x9c969b6b4c276096!2sCompass%20Glass%20Cape%20Town!5e0!3m2!1sen!2sza!4v1596397028089!5m2!1sen!2sza',
  },
};
